import React, { useState } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import { I18nextContext } from 'gatsby-plugin-react-i18next'
const SEO = ({
  // Props
  title,
  titleTemplate,
  description,
  img,
  imageWidth,
  imageHeight,
  pathname,
  article,
  keywords,
  lang,
  homepage,
  bodyClass,
}) => {
  var activeLanguage = React.useContext(I18nextContext).language
  if (activeLanguage === "mx") {
    activeLanguage = "es"
  }
  if (activeLanguage === "br") {
    activeLanguage = ("pt-br")
  }
  return (
    <StaticQuery
      query={query} // GraphQL
      render={({
        site: {
          // Render Props
          siteMetadata: { defaultTitle, defaultTitleTemplate, defaultDescription, siteUrl, defaultImg },
        },
      }) => {
        const seo = {
          // Either Props or GraphQL Fields
          title: title || defaultTitle,
          titleTemplate: titleTemplate || defaultTitleTemplate,
          description: description || defaultDescription,
          img: `${siteUrl}${img || defaultImg}`,
          imageWidth: imageWidth,
          imageHeight: imageHeight,
          keywords: keywords,
          url: `${siteUrl}${pathname || ""}`,
        }

        const WebSiteJSONLD = {
          "@context": "http://www.schema.org",
          "@type": "WebSite",
          name: "Ramalhos",
          alternateName: "Ramalhos - Pure Baking Pleasure",
          url: "https://ramalhos.com/",
        }

        const HomePageJSONLD = {
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Ramalhos",
          alternateName: "Ramalhos, S.A.",
          url: "https://ramalhos.com",
          logo: "https://ramalhos.com/SEO/logo.png",
          contactPoint: {
            "@type": "ContactPoint",
            telephone: "+351234630200",
            contactType: "customer service",
          },
          sameAs: [
            "https://www.facebook.com/ramalhos.ovens/",
            "https://www.instagram.com/ramalhos.ovens/",
            "https://www.youtube.com/channel/UCbyt5AhYkuuglVvD1QKDhwg",
            "https://www.linkedin.com/company/ramalhos/",
          ],
        }
        return (
          <>
            <Helmet
              htmlAttributes={{ lang }}
              bodyAttributes={bodyClass ? { class: bodyClass } : {}}
              title={seo.title}
              titleTemplate={seo.titleTemplate}
              defer={false}
            >
              {seo.description && <meta name="description" content={seo.description} />}
              {seo.keywords && <meta name="keywords" content={seo.keywords} />}
              {seo.image && <meta name="image" content={seo.img} />}
              {seo.url && <meta property="url" content={`${seo.url}`} />}
              {/* Open Graph */}
              {seo.url && <meta property="og:url" content={`${seo.url}`} />}
              {article ? <meta property="og:type" content="article" /> : <meta property="og:type" content="website" />}
              {seo.title && <meta property="og:title" content={seo.title} />}
              {seo.description && <meta property="og:description" content={seo.description} />}
              {/* {lang && <meta property="og:locale" content={langISO} />} */}
              {seo.img && <meta property="og:image" content={seo.img} />}
              {seo.imageWidth && <meta property="og:image:width" content={seo.imageWidth} />}
              {seo.imageHeight && <meta property="og:image:height" content={seo.imageHeight} />}
              {/* Twitter */}
              {seo.title && <meta name="twitter:title" content={seo.title} />}
              {seo.description && <meta name="twitter:description" content={seo.description} />}
              {seo.img && <meta name="twitter:image" content={seo.img} />}
              {seo.img && <meta name="twitter:card" content="summary_large_image" />}
              {/* {lang &&
              langOrder.map(ele => {
                return (
                  <link
                    key={ele}
                    rel="alternate"
                    hrefLang={ele}
                    href={`${siteUrl}/${ele === "x-default" ? "en" : ele}${
                      path ? `/${path}` : "/"
                    }`}
                  />
                )
              })} */}
              {homepage && <script type="application/ld+json">{JSON.stringify(WebSiteJSONLD)}</script>}
              {homepage && <script type="application/ld+json">{JSON.stringify(HomePageJSONLD)}</script>}
              {activeLanguage === 'mx' && <script type="text/javascript" async src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/ed9fa917-7afd-46cb-82d3-332db9cf1562-loader.js" ></script>}
              {activeLanguage === 'mx' && <script type="text/javascript" async src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/ed9fa917-7afd-46cb-82d3-332db9cf1562-loader.js" ></script>}
              {activeLanguage === 'br' && <script src="//code.tidio.co/1z3seakggwxyf7wd0oet6qzlskdivupy.js" async></script>}
              { }

              <script async src="https://www.googletagmanager.com/gtag/js?id=G-VHRF431NRG"></script>

              <script>
                {`window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied'})
gtag('set', 'ads_data_redaction', true)
gtag('config', 'G-VHRF431NRG');`}
              </script>

              <script>
              {`!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                  n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '265978786275614');
                fbq('track', 'PageView');`}
              </script>

              <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11288001937"></script>

              <link
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
              />

              <script src="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.js"></script>
              <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>

              <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/1d62e908fb8bd4d7b964c731/script.js"></script>

            </Helmet>
          </>
        )
      }}
    />
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  titleTemplate: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  img: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  lang: PropTypes.string,
  homepage: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  titleTemplate: "%s | Ramalhos",
  description: null,
  img: null,
  pathname: null,
  article: false,
  keywords: null,
  lang: "pt",
  homepage: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultTitleTemplate: titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImg: img
      }
    }
  }
`
